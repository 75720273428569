<template>
  <Header title="services" />

  <Container>
    <div class="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="s in services"
        :key="s.title"
        class="
          bg-accent-lightest
          flex flex-col
          items-center
          text-center
          pt-16
          p-8
          rounded-lg
          relative
        "
      >
        <div
          class="
            w-16
            h-16
            bg-accent-light
            shadow-lg
            grid
            place-items-center
            rounded-lg
            absolute
            -top-8
          "
        >
          <span v-html="s.icon" />
        </div>
        <h3 class="">{{ s.title }}</h3>
        <p v-html="s.description"></p>
      </div>
    </div>
  </Container>

  <SimpleBrandCTA />
</template>

<script>
import SimpleBrandCTA from "../../components/layouts/cta/SimpleBrandCTA.vue";
import Container from "../../components/layouts/Container.vue";
import Header from "@/components/base/Header";
import { SpeakerphoneIcon } from "@heroicons/vue/outline";

const services = [
  {
    title: "Speaking",
    description:
      "Keynote speaker about mental health and anxiety, how it affected me and what I did about it. Will also discuss how those mental health issues, planted the seed for an award-winning, health and wellness radio show.",
      icon: "<svg xmlns='http://www.w3.org/2000/svg' class='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'> <path stroke-linecap='round' stroke-linejoin='round' d='M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z' /> </svg>"
  },
  {
    title: "Marketing",
    description:
      "Will work with clients on how to market an event, taking it <strong>over and above</strong> the ordinary.",
      icon: "<svg xmlns='http://www.w3.org/2000/svg' class='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'> <path stroke-linecap='round' stroke-linejoin='round' d='M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z' /> </svg>"
  },
  {
    title: "Consulting",
    description:
      "Help businesses and charities get the biggest bang for the buck when it comes to news releases and how to avoid the mistakes that many people make.  <br/>Want to know how to get exposure?  I'll tell you how.",
    icon:"<svg xmlns='http://www.w3.org/2000/svg' class='h-10 w-10' fill='none' viewBox='0 0 24 24' stroke='currentColor' stroke-width='2'> <path stroke-linecap='round' stroke-linejoin='round' d='M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z' /> </svg>"
  },
];

export default {
  components: {
    Header,
    Container,
    SimpleBrandCTA,
    SpeakerphoneIcon,
  },
  data() {
    return {
      services,
    };
  },
};
</script>
